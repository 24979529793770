<script>
	import Vue from 'vue';
	import BaseVue from '@frontend/Base.vue';
	import Gen from '../helper/Gen';

	export default {
		extends: BaseVue,
		data() {
			return {
				wineries_content:{}
            }
		},
		async mounted() {
			await this.ready();
			if (typeof SEMICOLON != "undefined") SEMICOLON.documentOnReady.init()
			setTimeout(() => {
				$('[data-toggle="tooltip"]').tooltip();
				SEMICOLON.documentOnReady.init()
				SEMICOLON.slider.sliderParallax()
				SEMICOLON.widget.carousel()
			}, 300)
			this.$set(this.$root, 'page', this);
            this.getApi()
		},
        methods: {
        	getApi() {
				Gen.apirest("/wineries-list-content",{},(err,resp)=>{
					// this.$root.topProgress.done()
					if(err) console.log(err)
					this.wineries_content = resp.data
				})
			},
        },
	};
</script>
<template>
	<section id="content" style="background-color: #000; overflow: visible">
	    <div class="content-wrap pt-0 pb-0">
	        <div id="section-about" class="section page-section bg_white nobottommargin notopmargin clearfix">
	            <div class="container clearfix">
	                <div class="row justify-content-around dark">
	                    <div class="col-md-12">
	                        <div class="heading_section_light">
	                            <h2>{{ web.mn_wineries }}</h2>
	                        </div>
	                    </div>
	                    <div class="wrap_logo">
	                        <ul>
	                        	<li v-for="(v,k) in wineries_content" :key="k">
	                            	<a :href="v.awl_link" target="_blank" v-if="v.awl_link">
	                            		<img :src="uploader(v.awl_image, '186x186')" :alt="v.awl_client_name" :title="v.awl_client_name">
	                            	</a>
	                            	<img v-else :src="uploader(v.awl_image, '186x186')" :alt="v.awl_client_name" :title="v.awl_client_name">
	                            </li>
	                        </ul>
	                    </div>
	                </div>
	            </div>
	        </div>
	        <div class="clear"></div>
	    </div>
	    <div class="clear"></div>
	</div>
	</section>
</template>